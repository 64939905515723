import React, { useState } from 'react'
import { Editor } from "react-draft-wysiwyg"
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const RichTextEditor = ({editorState, onChangeEditor}) => {

  return (
    <div style={{border: '1px solid #070707'}}>
      {typeof window !== 'undefined' && <Editor
        editorState={editorState}
        onEditorStateChange={onChangeEditor}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: { uploadCallback: () =>{}, alt: { present: true, mandatory: true } },
        }}
      />
      }
    </div>
  )
}
export default RichTextEditor
