import {
  create as createProposal,
  getById as getProposalById,
  get as getProposals,
  getAndConcat as getProposalsAndConcat,
  update as updateProposal
} from '../types/proposal.js'
import axios from 'axios'

const baseURL = process.env.GATSBY_API_URL

const axiosInstance = axios.create({
  withCredentials: true,
  baseURL
})

export const create = dispatch => proposal => {
  dispatch({ type: createProposal.REQUEST})
  axiosInstance.post('/proposal', proposal)
    .then(response => dispatch({type: createProposal.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: createProposal.FAIL}))
}

export const getById = dispatch => proposalId => {
  dispatch({ type: getProposalById.REQUEST})
  axiosInstance.get(`/proposal/${proposalId}`)
    .then(response => dispatch({type: getProposalById.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: getProposalById.FAIL}))
}


export const get = dispatch => queryParams => {
  const queryString = Object.keys(queryParams).reduce((acc, current, index) => `${acc}${index > 0 ? '&': ''}${current}=${queryParams[current]}`, '?')
  dispatch({ type: getProposals.REQUEST})
  axiosInstance.get(`/proposal${queryString}`)
    .then(response => dispatch({type: getProposals.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: getProposals.FAIL}))
}

export const getAndConcat = dispatch => queryParams => {
  const queryString = Object.keys(queryParams).reduce((acc, current, index) => `${acc}${index > 0 ? '&': ''}${current}=${queryParams[current]}`, '?')
  dispatch({ type: getProposalsAndConcat.REQUEST})
  axiosInstance.get(`/proposal${queryString}`)
    .then(response => dispatch({type: getProposalsAndConcat.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: getProposalsAndConcat.FAIL}))
}

export const update = dispatch => proposal => {
  dispatch({ type: updateProposal.REQUEST})
  axiosInstance.put('/proposal', proposal)
    .then(response => dispatch({type: updateProposal.SUCCESS, data: response.data}))
    .catch(error => dispatch({type: updateProposal.FAIL}))
}
