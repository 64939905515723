import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import { EditorState } from 'draft-js'
import { convertFromRaw, convertToRaw } from 'draft-js'
import { pipe } from 'ramda'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import RichTextEditor from '../../components/richTextEditor'
import PdfItem from '../../components/pdfItem/'
import withLocation from '../../HOC/withLocation.js'

import { create, update, getById } from '../../reducers/actions/proposal.js'

import styles from './styles.module.css'

const Attachments = () => (
  <section>

    <button>Adjuntar archivos</button>
    <div style={{display: 'flex'}}>
      {[1,2,3,4,5].map(index => (
        <div key={index} style={{marginRight: '1rem'}}>
          <PdfItem filename={'ejemplo.pdf'}/>
        </div>
      ))}
    </div>
  </section>
)

const Proposal = ({search, location}) => {

  const {userReducer, proposalReducer } = useSelector(state => state)
  const dispatch = useDispatch()

  const projectId = location.state ? location.state.id : ''
  const id = search.id
  const [isFirstRender, setIsFirstRender] = useState(true)

  const [proposal, setProposal] = useState({
    description: EditorState.createEmpty(),
    price: '',
    attachments: [],
    user: '',
    project: projectId,
  })

  useEffect(() => {
    if(id && isFirstRender) {
      setIsFirstRender(false)
      getById(dispatch)(id)
    }
 
    if(userReducer.login.response) {
      setProposal({...proposal, user: userReducer.login.response._id})
    }

    if(proposalReducer.getProposalById.response) {
      const proposalRegistered = proposalReducer.getProposalById.response
      delete proposalRegistered.__v
      const {description} = proposalRegistered
      if (description) {
        proposalRegistered.description = EditorState.createWithContent(convertFromRaw(JSON.parse(description)))
      }
      setProposal(proposalRegistered)
    }

  }, [userReducer.login, proposalReducer.getProposalById])

  useEffect(() => {
    const create = proposalReducer.createProposal.response
    const update = proposalReducer.updateProposal.response
    if(
      (create && create.publish) || 
      (update && update.publish)
    ){

      navigate('/provider-profile', {state: {tab: 'Mis Propuestas'}})
    }
  }, [proposalReducer.updateProposal, proposalReducer.createProposal])


  const onChangeDescription = editorState => setProposal({...proposal, description: editorState})
  
  const setPrice = pipe(
    event => event.target.value,
    price => price.replace(/\D/g,''),
    price => setProposal({...proposal, price})
  )

  const dottedNumber = number => number.replace(/\B(?=(\d{3})+(?!\d))/g, ".")

  const save = () => {
    const description = JSON.stringify(convertToRaw(proposal.description.getCurrentContent()))
    const payload = {...proposal, description}
    const createResponse = proposalReducer.createProposal.response
    if(!createResponse && !id) {
      create(dispatch)(payload)
    } else {
      payload._id = createResponse ? createResponse._id : id
      update(dispatch)(payload)
    }
  }

  const send = () => {
    const description = JSON.stringify(convertToRaw(proposal.description.getCurrentContent()))
    const payload = {...proposal, description, publish: true }
    const createResponse = proposalReducer.createProposal.response
    if(!createResponse && !id) {
      create(dispatch)(payload)
    } else {
      payload._id = createResponse ? createResponse._id : id
      update(dispatch)(payload)
    }
  }

  return (
    <Layout>
      <SEO title='Propuesta' />
      <h1>Propuesta</h1>
      <div className={styles.container} > 
        <label> 
          Descripción
          <div> 
            <RichTextEditor
              editorState={proposal.description}
              onChangeEditor={onChangeDescription}
            />
          </div>
        </label>

        <label className={styles.budget}>
          Presupuesto
          <input
            name='price'
            value={dottedNumber(proposal.price)}
            onChange={setPrice}
          />
        </label>

        <div className={styles.attachments}>
          <Attachments/>
        </div>

        <div className={styles.actions}>
          <button onClick={save}>Guardar</button>
          <button onClick={send}>Enviar</button>
        </div>
      </div>
    </Layout>
  )
}
export default withLocation(Proposal) 
